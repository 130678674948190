import { SignInButton, useUser } from "@clerk/nextjs";
import { isFilled } from "@prismicio/helpers";
import { motion } from "framer-motion";
import { useTheme } from "next-themes";
import { type HeroSlice } from "prismicio-types";
import { useEffect, useState } from "react";
import { ButtonScroll } from "~/core/components/Basic/ButtonCustom/ButtonScroll/ButtonScroll";
import { variants } from "~/core/styles/animations";
import { cn } from "~/core/styles/helpers";
import { Button } from "~/coreUI/Basic/Button/Button";
import { RichText } from "~/coreUI/Content/RichText/RichText";

interface HeroProps {
  slice: HeroSlice;
}

export const Hero = ({ slice }: HeroProps) => {
  const { isSignedIn } = useUser();
  const { resolvedTheme } = useTheme();
  const { title, paragraph, buttonlabel, color } = slice.primary;
  const [backgroundImage, setBackgroundImage] = useState("");

  useEffect(() => {
    setBackgroundImage(
      resolvedTheme === "dark"
        ? `static/images/background-pattern-dark.svg`
        : `static/images/background-pattern.svg`
    );
  }, [resolvedTheme]);

  return (
    <div
      className={cn(
        isFilled.select(color) ? color : "primary",
        "relative flex max-h-[1000px] min-h-[calc(100svh-80px)] shrink-0 flex-col items-center justify-center self-stretch bg-auto bg-repeat px-0 pb-2 text-xl"
      )}
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <motion.div
        className="flex shrink-0 flex-col items-center justify-center gap-16 self-stretch py-16"
        variants={variants.container}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
      >
        <div className=" flex w-full max-w-[30rem] flex-col items-start justify-start px-8">
          <motion.div
            className=" self-stretch font-black"
            variants={variants.fromLeft}
            key={"title"}
          >
            <RichText field={title} hasAnimation={false} />
          </motion.div>
          {paragraph && (
            <motion.div
              className=" self-stretch text-[1rem] font-light text-black "
              variants={variants.fromLeft}
              key={"paragraph"}
            >
              <RichText field={paragraph} />
            </motion.div>
          )}
        </div>

        <motion.div
          className="h-[90px]"
          key={"button"}
          variants={variants.fromLeft}
        >
          {isSignedIn ? (
            <Button
              href="/dashboard"
              label="Go to dashboard"
              size="lg"
              as="a"
              hasShadow
            />
          ) : (
            <SignInButton mode="modal" redirectUrl="/dashboard">
              <Button size="lg" label={buttonlabel} hasShadow />
            </SignInButton>
          )}
        </motion.div>
      </motion.div>
      <ButtonScroll />
    </div>
  );
};
