import { cva } from "class-variance-authority";

export const button = cva("w-fit rounded-md", {
  variants: {
    size: {
      xs: "text-2xs py-1 px-3 gap-2 font-medium",
      sm: "text-xs py-2 px-2 gap-2 min-w-[100px] font-medium",
      md: "text-xs p-3 py-3 gap-4 font-bold min-w-[140px]",
      lg: "text-sm p-8 px-10 gap-6 font-bold min-w-[140px]",
    },
    shapeStyle: {
      fill: "!border-transparent border-0 disabled:!bg-grey disabled:!text-white",
      outline:
        "disabled:!text-grey disabled:!border-grey disabled:bg-transparent",
    },
    isHeadless: {
      true: "dark:text-white text-black shadow-none border-[1px]",
      false: "",
    },
  },
});

export const buttonHeadless = cva("w-full rounded-xs", {
  variants: {
    bgColor: {
      auto: "bg-transparent !text-black dark:!text-white",
      soft: "hover:bg-white hover:dark:bg-black",
      default: "hover:bg-grey-light hover:dark:bg-grey-dark",
    },
    color: {
      default: "text-black dark:text-white",
      soft: "text-black dark:text-white",
      primary: "text-primary-dark   dark:text-primary-light",
      secondary: "text-secondary-dark  dark:text-secondary-light",
      tertiary: "text-tertiary-dark  dark:text-tertiary-light",
      quaternary: "text-quaternary-dark  dark:text-quaternary-light",
      quinary: "text-quinary-dark  dark:text-quinary-light",
      danger: "text-danger-dark  dark:text-danger-light",
      warning: "text-warning-dark  dark:text-warning-light",
      success: "text-success-dark  dark:text-success-light",
    },
  },
});

export const buttonIconNoLabel = cva(
  "flex shrink-0 flex-row items-center justify-center rounded-full min-w-fit p-0",
  {
    variants: {
      size: {
        xs: "h-5 w-5",
        sm: "h-7 w-7",
        md: "h-10 w-10",
        lg: "h-16 w-16",
      },
    },
  }
);

export const buttonIcon = cva("block", {
  variants: {
    size: {
      xs: "h-3 w-3",
      sm: "h-4 w-4",
      md: "h-5 w-5",
      lg: "h-8 w-8",
    },
  },
});
