import { type Content } from "@prismicio/client";
import { type SliceComponentProps } from "@prismicio/react";
import { Hero as Component } from "~/coreUI/Content/Slices/Hero/Hero";

export type HeroProps = SliceComponentProps<Content.HeroSlice>;

const Hero = ({ slice }: HeroProps): JSX.Element => {
  return (
    <div
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <Component slice={slice} />
    </div>
  );
};

export default Hero;
