import { isFilled } from "@prismicio/helpers";
import { type ImageField } from "@prismicio/types";
import NextImage, {
  type ImageLoader,
  type ImageProps as NextImageProps,
} from "next/image";

type ImageOptions = Omit<NextImageProps, "src" | "width" | "height" | "loader">;

export type ImageProps = {
  image: ImageField;
} & ImageOptions;

const prismicLoader: ImageLoader = ({ src, width }) => {
  const url = new URL(src);
  url.searchParams.set("w", width.toString());
  url.searchParams.delete("h");
  return url.toString();
};

function buildImage(image: ImageField, options?: ImageOptions) {
  if (!isFilled.image(image)) {
    return null;
  }

  const nextProps: NextImageProps = {
    ...options,
    src: image.url,
    alt: image.alt ?? "",
    loader: prismicLoader,
  };

  const layout = options?.layout ?? "intrinsic";

  if (layout !== "fill") {
    nextProps.width = image.dimensions.width;
    nextProps.height = image.dimensions.height;
  }

  // limit width of images used in intrinsic or fixed layouts in case a big image
  // without constraints is used, impacting load performance negatively
  if (layout === "intrinsic" || layout === "fixed") {
    const { width, height } = image.dimensions;
    nextProps.width = width;
    nextProps.height = height * (nextProps.width / width);
  }

  return <NextImage {...nextProps} />;
}

export const Image: React.FC<ImageProps> = ({ image, ...options }) => {
  return <>{buildImage(image, options)}</>;
};
