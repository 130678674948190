import { PrismicLink } from "@prismicio/react";
import { type LinkField } from "@prismicio/types";
import { type VariantProps } from "class-variance-authority";
import Link from "next/link";
import { type ReactElement } from "react";
import {
  baseStyle,
  bgStyles,
  borderStyles,
  shadowStyles,
  textStyles,
} from "~/core/styles/cvaStyles";
import { cn } from "~/core/styles/helpers";
import { type UiElement, type allColors, type sizes } from "~/core/types";
import { button, buttonHeadless, buttonIcon, buttonIconNoLabel } from "./utils";

export interface ButtonBaseProps extends UiElement {
  as?: "a" | "button" | "div" | "prismicLink";
  href?: string;
  field?: LinkField;
  onClick?: () => void;
  status?: "enabled" | "disabled";
  size?: sizes;
  label?: string | null;
  icon?: ReactElement | null;
  isHeadless?: boolean;
  color?: allColors;
  bgColor?: "auto" | "soft" | "default";
  hasShadow?: boolean;
  iconPosition?: "start" | "end";
}

export type ButtonProps = ButtonBaseProps & VariantProps<typeof button>;

export const Button = (props: ButtonProps) => {
  const {
    as = "button",
    href,
    onClick,
    field,
    label,
    icon,
    size = "md",
    shapeStyle = "fill",
    status = "enabled",
    color = "primary",
    bgColor = "auto",
    hasShadow = false,
    isHeadless = false,
    iconPosition = "end",
    className,
    ...passthroughProps
  } = props;

  const commonProps = cn(
    baseStyle({ hasShadow }),
    shadowStyles({ size, color }),
    bgStyles({ bgColor: color }),
    textStyles({ color }),
    borderStyles({ color, size }),
    button({
      size,
      shapeStyle,
      isHeadless,
    })
  );

  const specialProps = cn(
    shapeStyle === "outline" &&
      (bgColor === "auto" ? "bg-default" : bgStyles({ bgColor })),
    shapeStyle === "fill" &&
      (bgColor === "auto" ? "!text-white" : bgStyles({ bgColor })),
    // status === "disabled" && buttonDisabled({ shapeStyle }),
    isHeadless &&
      cn(
        buttonHeadless({
          bgColor,
          color,
        }),
        bgStyles({ bgColor })
      ),
    !label && buttonIconNoLabel({ size })
  );
  ("");
  const baseProps = {
    className: cn(
      "flex h-fit flex-initial items-center text-start disabled:shadow-none cursor-pointer disabled:cursor-not-allowed",
      as === "div" && "!cursor-auto",
      commonProps,
      specialProps,
      className
    ),
    ...passthroughProps,
  };

  const Icon = ({ className }: UiElement) => (
    <span className={cn(className, buttonIcon({ size }))}>{icon}</span>
  );

  const InnerContent = () => (
    <>
      {icon && iconPosition === "start" && <Icon />}
      {label && <span>{label}</span>}
      {icon && iconPosition === "end" && (
        <Icon className={!label ? "" : "ml-auto"} />
      )}
    </>
  );

  const Component = as === "button" ? "button" : "div";

  const isValidLink = !!(as === "a" && href);
  const isValidPrismicLink = as === "prismicLink" && field;

  return (
    <>
      {isValidLink || isValidPrismicLink ? (
        <>
          {isValidLink && (
            <Link {...baseProps} href={href}>
              <InnerContent />
            </Link>
          )}
          {isValidPrismicLink && (
            <PrismicLink {...baseProps} field={field}>
              <InnerContent />
            </PrismicLink>
          )}
        </>
      ) : (
        <Component
          {...baseProps}
          onClick={onClick}
          disabled={status === "disabled"}
        >
          <InnerContent />
        </Component>
      )}
    </>
  );
};
