import { cva } from "class-variance-authority";

export const borderStyles = cva("", {
  variants: {
    color: {
      primary: "border-primary",
      secondary: "border-secondary",
      tertiary: "border-tertiary",
      quaternary: "border-quaternary",
      quinary: "border-quinary",
      danger: "border-danger",
      warning: "border-warning",
      success: "border-success",
      default: "dark:border-grey",
      soft: "border-white dark:border-black",
    },
    size: {
      xs: "border-[1px]",
      sm: "border-[2px]",
      md: "border-[2px]",
      lg: "border-[3px]",
    },
  },
});

export const textStyles = cva("", {
  variants: {
    color: {
      primary: "text-primary",
      secondary: "text-secondary",
      tertiary: "text-tertiary",
      quaternary: "text-quaternary",
      quinary: "text-quinary",
      danger: "text-danger",
      warning: "text-warning",
      success: "text-success",
      default: "text-black dark:text-white",
      soft: "text-default-soft",
      auto: "",
    },
  },
});

export const bgStyles = cva("", {
  variants: {
    bgColor: {
      primary: "bg-primary",
      secondary: "bg-secondary",
      tertiary: "bg-tertiary",
      quaternary: "bg-quaternary",
      quinary: "bg-quinary",
      danger: "bg-danger",
      warning: "bg-warning",
      success: "bg-success",
      default: "bg-default",
      soft: "bg-default-soft",
      auto: "",
    },
  },
});

export const shadowStyles = cva("", {
  variants: {
    size: {
      xs: "shadow-[0px_0px_5px_transparent]",
      sm: "shadow-[0px_0px_10px_transparent]",
      md: "shadow-[0px_0px_25px_transparent]",
      lg: "shadow-[0px_0px_100px_transparent]",
    },
    color: {
      primary: "shadow-primary",
      secondary: "shadow-secondary",
      tertiary: "shadow-tertiary",
      quaternary: "shadow-quaternary",
      quinary: "shadow-quinary",
      danger: "shadow-danger",
      warning: "shadow-warning",
      success: "shadow-success",
      default: "!shadow-white dark:!shadow-black",
      soft: "!shadow-grey-light dark:!shadow-grey-dark",
      auto: "",
    },
  },
});

export const baseStyle = cva("", {
  variants: {
    hasShadow: {
      true: "",
      false: "!shadow-none",
    },
  },
});
