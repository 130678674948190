import { motion, useInView, useAnimation } from "framer-motion";
import { Dropdown } from "../../icons";
import { useEffect, useRef } from "react";
import { transition } from "~/core/styles/animations";

export const ButtonScroll = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const controls = useAnimation();

  const variants = {
    hidden: { scale: 0 },
    visible: {
      scale: 1,
      transition: {
        ...transition.normal,
        delay: 0.5,
      },
    },
    pulsate: {
      scale: [1, 1.2, 1],
      transition: {
        duration: 1,
        ease: "easeInOut",
        repeat: Infinity,
        times: [0, 0.5, 1],
        repeatDelay: 2,
      },
    },
  };

  useEffect(() => {
    if (isInView) {
      void controls.start("visible").then(() => {
        void controls.start("pulsate");
      });
    }
  }, [isInView, controls]);

  return (
    <motion.button
      ref={ref}
      aria-label="scroll to next section"
      className="absolute bottom-10"
      onClick={() => {
        window.scrollTo({
          top: window.innerHeight,
          behavior: "smooth",
        });
      }}
      initial="hidden"
      animate={controls}
      variants={variants}
    >
      <Dropdown className="w-6 text-grey" />
    </motion.button>
  );
};
