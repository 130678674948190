import React from "react";
import NextLink from "next/link";
import { type LinkField, type PrismicDocument } from "@prismicio/types";
import { PrismicLink } from "@prismicio/react";

interface LinkProps {
  href?: string;
  field?: LinkField;
  document?: PrismicDocument;
  children: React.ReactElement;
  target?: string;
  prefetch?: boolean;
}

interface LinkComponentProps {
  href: string;
}

export const Link: React.FC<LinkProps> = (props) => {
  const { children, document, field, target, prefetch } = props;

  return (
    <PrismicLink
      document={document}
      field={field}
      internalComponent={({ href }: LinkComponentProps) => (
        <NextLink href={href} passHref prefetch={prefetch}>
          {children}
        </NextLink>
      )}
      externalComponent={(props: LinkComponentProps) =>
        React.cloneElement(React.Children.only(children), props)
      }
      target={
        (field?.link_type === "Media" || field?.link_type === "Web") &&
        target == null
          ? "_blank"
          : target
      }
    />
  );
};
