import { type JSXMapSerializer, PrismicRichText } from "@prismicio/react";
import { type RichTextField } from "@prismicio/types";
import { Link } from "~/coreUI/Basic/Link/Link";
import { Image } from "~/coreUI/Content/Image/Image";
import { type baseColors } from "~/core/types";
import { motion } from "framer-motion";
import { animationBaseProps, variants } from "~/core/styles/animations";
import { cva } from "class-variance-authority";

export type RichTextSpecialLinkHandlers = Record<string, () => void>;

export interface RichTextProps {
  field: RichTextField;
  specialLinks?: RichTextSpecialLinkHandlers;
  components?: JSXMapSerializer;
  color?: baseColors;
  hasAnimation?: boolean;
}

export const textStyles = cva("", {
  variants: {
    tag: {
      paragraph: "",
      em: "",
      heading1: "font-black text-xl leading-[3rem] mb-0",
      heading2: "font-black text-lg leading-[2.2rem] mt-10 mb-6",
      heading3: "font-bold text-md leading-[1.8rem]",
      heading4: "font-bold text-sm leading-[1.5rem]",
      heading5: "font-bold text-xs leading-[1.2rem]",
      hyperlink: "text-secondary underline",
      list: "list-inside list-disc",
      oList: "list-inside list-decimal",
    },
  },
});

export const RichText: React.FC<RichTextProps> = (props) => {
  const {
    field,
    specialLinks: specialLinkHandlers,
    components,
    color,
    hasAnimation = true,
  } = props;

  return (
    <div className={color ?? ""}>
      <PrismicRichText
        field={field}
        components={{
          heading1: ({ key, children }) => (
            <motion.h1
              key={key}
              className={textStyles({ tag: "heading1" })}
              {...(hasAnimation && {
                ...animationBaseProps,
                variants: variants.fromLeft,
              })}
            >
              {children}
            </motion.h1>
          ),
          heading2: ({ key, children }) => (
            <motion.h2
              key={key}
              className={textStyles({ tag: "heading2" })}
              {...(hasAnimation && {
                ...animationBaseProps,
                variants: variants.fromLeft,
              })}
            >
              {children}
            </motion.h2>
          ),
          heading3: ({ key, children }) => (
            <h3
              key={key}
              className={textStyles({ tag: "heading3" })}
              {...(hasAnimation && {
                ...animationBaseProps,
                variants: variants.fromLeft,
              })}
            >
              {children}
            </h3>
          ),
          heading4: ({ key, children }) => (
            <h4
              key={key}
              className={textStyles({ tag: "heading4" })}
              {...(hasAnimation && {
                ...animationBaseProps,
                variants: variants.fromLeft,
              })}
            >
              {children}
            </h4>
          ),
          hyperlink: ({ key, children, node }) => {
            const url = node.data.url;

            if (url != null && specialLinkHandlers?.[url] != null) {
              return (
                <a
                  href="#"
                  className={textStyles({ tag: "hyperlink" })}
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                >
                  {children}
                </a>
              );
            }

            return (
              <Link field={node.data} key={key}>
                <a className={textStyles({ tag: "hyperlink" })}>{children}</a>
              </Link>
            );
          },
          image: ({ key, node }) => (
            <Image key={key} image={node} alt={node.alt ?? ""} />
          ),
          paragraph: ({ children, key }) => (
            <motion.p
              key={key}
              className={textStyles({ tag: "paragraph" })}
              {...(hasAnimation && {
                ...animationBaseProps,
                variants: variants.fromLeft,
              })}
            >
              {children}
            </motion.p>
          ),
          list: ({ key, children }) => (
            <ul key={key} className={textStyles({ tag: "list" })}>
              {children}
            </ul>
          ),
          oList: ({ key, children }) => (
            <ol key={key} className={textStyles({ tag: "oList" })}>
              {children}
            </ol>
          ),
          em: ({ key, children }) => (
            <em key={key} className={textStyles({ tag: "em" })}>
              {children}
            </em>
          ),
          ...components,
        }}
      />
    </div>
  );
};
